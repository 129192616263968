<template>
  <router-link
    v-if="orderId"
    :to="route"
    target="_blank"
  >
    {{orderId}}
  </router-link>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  computed: {
    route() {
      return {
        name: 'order-update',
        params: { target: this.orderId },
      }
    },
    orderId() {
      return this.row.order_id
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
